// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

$navbar-light-color : #05500B;

$navbar-light-active-color : #05500B;

$navbar-light-hover-color : #DB4802;

$navbar-light-hover-color-unused : #DB4802;