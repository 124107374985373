// 
// user.scss
// Use this to write your custom SCSS
//
.olili-primary {
    color: $orange;
}
.olili-bg {
    color: $bg-orange;
}
.olili-bg-light{
    color: $bg-orange-light;
}
.olili-secondary {
    color: $green;
}